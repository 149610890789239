<script setup lang="ts"></script>
<template>
  <div class="terms-wrapper">
    <p style="margin-bottom: 8pt; text-align: center; line-height: 75%; font-size: 16pt">
      <span>Resource Cloud</span><span>顧客向けサービス利用規約</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: center; line-height: 75%">
      <span>第</span><span>1</span><span>章 総則</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>1</span><span>条（適用）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>Resource Cloud</span
        ><span>顧客向けサービス利用規約（以下「本規約」といいます）は、株式会社</span
        ><span>INDUSTRIAL-X</span
        ><span
          >（以下「当社」といいます）が運営するプラットフォームサービス（以下「本サービス」といいます）を、利用者（第</span
        ><span>2</span
        ><span
          >条以下に定めます）が利用するに当たっての利用条件、その他利用者と当社との間の基本的な権利義務関係を定めています。本規約は、利用者による本サービスの利用及び本サービスを通して行われる取引に関する一切の事項に適用されます。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >利用者は、当該利用者が希望するサービスに応じて、当社との間で別途個別の契約（以下「個別取引契約」といいます）を締結することがあります。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >利用者は、本サービスを利用する場合には、当該利用者に適用される一切の本規約及び個別取引契約の規定を遵守することに同意したものとみなします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >本規約の内容と個別取引契約の内容に矛盾または抵触がある場合、個別取引規約の規定が優先して適用されるものとします。</span
        >
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>2</span><span>条（定義）</span>
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>1.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>本規約において使用する以下の用語は、以下に定義された意味を有するものとします。</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>①</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>「ビジター」とは、会員登録をせずに本サービスを閲覧・利用する者をいいます。</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>②</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>「</span><span>RC</span
      ><span>エントリー」とは、無料の会員登録の上、本サービスを閲覧・利用する者をいいます。</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>③</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>「</span><span>RC</span
      ><span
        >スタンダード」とは、有料の会員登録の上、本サービスを閲覧・利用する者であって、個人または個人事業主として申し込みを行う者をいいます。なお、</span
      ><span>RC</span><span>スタンダードは</span>
      <span
        >RCエントリーの全機能に加えて、オプションとして企画支援サービスである「DX
        plus」を含んでおり、さらに、別途チケット制相談サービス「スポットコンサルティング」を申込むことができます。
      </span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>④</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>「</span><span>RC</span
      ><span
        >ビジネス」とは、有料の会員登録の上、本サービスを閲覧・利用する者であって、法人として申し込みを行う者をいいます。なお、</span
      ><span>RC</span><span>ビジネスは</span><span>RC</span
      ><span>エントリーの全機能に加えて、オプションとして企画支援サービスである「</span
      ><span
        >DX plus」及びチケット制相談サービス「スポットコンサルティング」が含まれています。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑤</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>「利用者」とは、ビジター、</span><span>RC</span><span>エントリー、</span><span>RC</span
      ><span>スタンダード、</span><span>RC</span><span>ビジネスを総称していいます。</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑥</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>「登録会員」とは、</span><span>RC</span><span>エントリー、</span><span>RC</span
      ><span>スタンダード、</span><span>RC</span><span>ビジネスを総称していいます。</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑦</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >「サービス利用契約」とは、登録会員と当社との間で成立し、本規約及び個別取引契約が一体となって構成され、当該登録会員が本サービスを利用するに際して適用される本サービスの利用契約をいいます。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑧</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>「知的財産権等」とは、特許権、実用新案権、意匠権、著作権（著作権法第</span
      ><span>27</span><span>条及び第</span><span>28</span
      ><span
        >条の権利を含みます）及び商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます）並びにノウハウ等をいいます。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑨</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >「データ」とは、本サービスの利用に起因してまたは関連して創出、取得または収集される電磁的記録（電子的方式、磁気的方式、その他人の知覚によっては認識できない方式で作成される記録であって、電子計算機による情報処理の用に供されるもの）をいいます。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑩</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >「パートナー」とは、当社と提携し本サービスを介して、利用者にリソースを提供する事業者をいいます。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑪</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >「リソース取引契約」とは、本サービスを介したリソース提供取引に関して、パートナーまたは当社と、利用者との間で締結される、リソース提供取引に係る契約を総称していいます。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑫</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >「統計情報」とは、個々の利用者に関する登録情報・利用情報またはリソース提供取引に関する取引情報を加工し、集計して得られる情報であり、特定の利用者または特定のリソース提供取引が特定または識別されないように統計的に処理された情報をいいます。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑬</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >「取引情報」とは、本サービスを通じた、パートナーまたは当社と登録会員との間のリソース提供取引に関する情報及びその付帯情報をいいます（登録会員の名称等の当事者情報、リソース提供取引に係るリソースの内容、数量、価格、その他の取引条件等に関する情報を含みますが、これに限られません）。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑭</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >「リソース稼働データ」とは、パートナーと登録会員との間で成立するリソース取引契約に基づき導入された製品または人員の稼働に起因しまたはこれに関連して創出、収集されるデータその他の情報をいいます。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑮</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>「リソース提供取引」とは、リソース取引契約に基づく取引をいいます。</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑯</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >「利用・分析」とは、別段の定めがない限り、利用、複製、加工、編集、統合または分析等をいいます。</span
      >
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>2.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >本規約において定義された用語は、特に定めのない限り、他の個別規約及び個別取引契約においても同一の意義を有するものとします。</span
      >
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: center; line-height: 75%">
      <span>第</span><span>2</span><span>章 会員登録</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>3</span><span>条（登録）</span>
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>1.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >本サービスの会員登録を希望する者（以下「登録希望者」といいます）は、当社の定める一定の情報（以下「登録情報」といいます）を当社の定める方法で提供することにより、当社に対し、会員登録を申請するものとします。</span
      >
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>2.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>当社は、当社が定める基準に従って、第</span><span>1</span
      ><span
        >項に基づく登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録希望者に通知します。</span
      >
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>3.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>前項に定める通知に記載された利用開始日（</span><span>RC</span
      ><span
        >エントリーは前項の通知日を指します。以下、「利用開始日」といいます）に、サービス利用契約が登録会員と当社の間に成立し、登録会員は本サービスを利用することができるようになります。なお、登録希望者の登録会員としての登録は、当社による本項の通知の発送（</span
      ><span>RC</span><span>エントリーは除きます）をもって完了したものとします。</span>
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>4.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >当社は、登録希望者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあり、またその理由について一切開示義務を負いません。なお、登録後においても登録希望者が各号に該当することが判明した場合には、当社の判断により登録を解除することができるものとし、その理由について当社は一切開示義務を負いません。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>①</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>当社に提供した登録情報の全部または一部につき虚偽、誤記または記載漏れがあった場合</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>②</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >自らまたは自らの役員（取締役、執行役、執行役員、監査役、相談役、会長その他、名称の如何を問わず、経営に実質的に関与しているものをいいます。以下同じ）が、第</span
      ><span>16</span><span>条（反社会的勢力の排除）第</span><span>1</span><span>項または第</span
      ><span>2</span><span>項のいずれかに該当すると当社が判断した場合</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>③</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >登録希望者が過去当社との契約に違反した者またはその関係者であると当社が判断した場合</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>④</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>その他、当社が登録を適当でないと判断した場合</span>
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>5.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >当社は、本条に基づき当社が行った行為により登録会員に生じた損失、損害について一切の責任を負いません。</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>4</span><span>条（登録事項の変更）</span>
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span
        >登録会員は、登録情報に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: center; line-height: 75%">
      <span>第</span><span>3</span><span>章 本サービス</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>5</span><span>条（本サービスの目的）</span>
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span>本サービスは、登録会員に対し、本サービスを通じて、デジタルトランスフォーメーション</span
      ><span>(DX)</span><span>を実現するために必要となるリソースを提供すること（</span
      ><span>Resource as a Service</span><span>（</span><span>”RaaS”</span
      ><span>））を目的とします。</span>
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>6</span><span>条（本サービスの内容）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >当社は、本プラットフォームを通じて、登録会員に対し、本サービスを提供するものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >本サービスの具体的内容は、登録会員が利用するサービスに対応し、当社が別途定めるものとします。</span
        >
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>7</span><span>条（利用料金及び支払方法）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>本サービスの利用料金は、</span><span>別途担当者にご確認ください。</span
        ><span>本サービスの支払方法は別紙記載の通りとなります。</span>
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>お申込みプランの変更については、別途担当者にご連絡ください。</span>
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >リソース提供取引に係る利用料金及び支払方法は、リソース取引契約において別途定めるものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>
          その他当社は登録会員に対しオプションサービスを提供する場合があり、オプションサービスの利用料金及び支払方法は、本サービスのWEBサイトへの掲示等の方法により通知するものとします。
        </span>
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>8</span><span>条（本サービスの委託）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >当社は、本サービスの提供に係る業務の全部または一部を第三者に委託することができるものとします。但し、当社は当該委託先を善良なる管理者の注意をもって管理監督するものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >前項の規定にかかわらず、リソース取引契約に別段の定めがある場合にはこれに従うものとします。</span
        >
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>9</span><span>条</span><span>(</span><span>情報管理</span><span>)</span>
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span
        >当社は、本サービスの提供に関連して管理・保有する情報及びデータにつき、善良なる管理者の注意をもって機密保持とその管理に努めるものとし、紛失・破壊・改ざん・漏えい等の危険から保護するための合理的な安全管理措置を施し、適正に管理するものとします。</span
      >
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: center; line-height: 75%">
      <span>第</span><span>4</span><span>章 権利帰属</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>10</span><span>条（当社による取引情報の利用）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>当社は、</span><span>(1)</span><span>本サービスの提供、改良または新機能の開発、</span
        ><span>(2)</span><span>本サービスの利用状況の調査、</span><span>(3)</span
        ><span>本サービスの有効性、利便性等の向上、</span><span>(4)</span
        ><span>その他利用者に最適のリソースを提供すること等を目的として、登録会員の</span
        ><span>(i)</span><span>登録情報及びその他の提供情報、</span><span>(ii)</span
        ><span>取引情報、並びに</span><span>(iii)</span
        ><span
          >本サービスの利用状況に関する情報を収集し、管理及び保管するものとします。当社は、上記目的のために必要な範囲で、これらの情報を利用することができるものとします。登録会員は、本サービスを利用する場合、当社がかかる利用を行うことに、あらかじめ同意したものとみなします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>当社は、本サービスの提供を目的として、第</span><span>1</span
        ><span
          >項に基づき収集し、管理及び保管する登録会員に関する情報を、当該登録会員に対して提供することができるものとし、当該登録会員は、これを利用することができるものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>当社は、本サービスの提供を目的として、第</span><span>1</span
        ><span
          >項に基づき加工、編集して作成した統計情報または分析結果（但し、特定の登録会員または特定の取引情報が識別できない場合に限ります）を登録会員（他の登録会員を含みます）に提供することができるものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>第</span><span>1</span
        ><span>項に定める登録会員の登録情報及び取引情報等に加え、当社は、</span><span>(1)</span
        ><span>統計情報の取得、</span><span>(2)</span><span>リソース提供取引の利用状況の調査、</span
        ><span>(3)</span><span>リソース提供取引の有効性、利便性等の向上、</span><span>(4)</span
        ><span
          >その他登録会員に最適のリソースを提供することを目的として、以下に定めるデータ及び情報を収集し管理及び保管することができるものとします。当社は、上記目的のために必要な範囲で、これらのデータ及び情報を利用することができるものとします。登録会員は、本プラットフォームを通じてリソース提供取引を行う場合、当社がかかる利用・分析を行うことに、あらかじめ同意したものとみなします。</span
        >
        <ol type="1" style="margin-right: 0; margin-left: 0; padding-left: 0">
          <li style="margin-left: 30.88pt; margin-bottom: 8pt; padding-left: 5.12pt">
            <span>登録会員のリソース提供取引に係るリソース稼働データ</span>
          </li>
          <li style="margin-left: 30.88pt; margin-bottom: 8pt; padding-left: 5.12pt">
            <span>登録会員に対して実施する情報提供その他のアンケートの回答結果等の情報</span>
          </li>
        </ol>
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>登録会員は、当社に対し、当社が第</span><span>4</span
        ><span
          >項に基づき収集し、管理及び保管する自己のリソース提供取引に係るリソース稼働データにつき、提供を求めることができ、当該登録会員は、自己利用目的で、これらの情報を利用することができるものとします。但し、別途認められる場合を除き、公表または第三者提供することはできないものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>当社は、本サービスの提供を目的として、第</span><span>4</span
        ><span
          >項に基づき加工、編集して作成した統計情報または分析結果（但し、特定の登録会員または特定の取引情報が識別できない場合に限ります）を登録会員（他の登録会員を含みます）に提供することができるものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>当社は、</span><span>(i)</span><span>本条第</span><span>1</span
        ><span
          >項に基づき収集し、管理及び保管する登録会員のリソース提供取引の取引情報（当社と登録会員との間でリソース取引契約が締結される場合に限る。）または</span
        ><span>(ii)</span><span>本条第</span><span>4</span
        ><span
          >項に基づき収集し、管理及び保管する登録会員のリソース提供取引に係るリソース稼働データ（当社と登録会員との間でリソース取引契約が締結される場合を含む）を、登録会員に最適のリソースを提供することを目的として、当該目的に必要な範囲で、当該リソースを提供するパートナーに提供することができるものとし、登録会員は、当社がかかる提供を行うことに、あらかじめ承諾するものとします。但し、当該登録会員が承諾しない旨を当社所定の方法で事前に通知した場合はこの限りではありません。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >当社は、別途定めがある場合を除き、登録会員との間のサービス利用契約の終了後または登録会員の登録抹消後においても、第</span
        ><span>1</span><span>項及び第</span><span>4</span
        ><span
          >項に定める当社が管理及び保管する情報を、本規約の定めに従って利用できるものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >当社による、登録会員に係る個人情報の取り扱いについては、別途定めるプライバシーポリシーに従うものとし、登録会員はこれに同意するものとします。</span
        >
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>11</span><span>条（登録情報の開示）</span>
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>1.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >当社は、登録会員が本サービスを利用してパートナーのリソースについて当該パートナーまたは当社との間でリソース提供取引を行うにあたり、本サービスを利用したリソース提供取引の実現を目的として、本プラットフォームを通じて、パートナーに対し、当該登録会員の登録情報及び過去の取引情報を、開示することができるものとし、登録会員は、当社が当該開示を行うことに同意します。</span
      >
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>2.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >前項の規定にかかわらず、当社は、登録会員に関する以下に定める取引情報を、パートナー及び他の登録会員に開示または提供することはありません。但し、本規約に定める範囲内で、統計情報または分析結果（特定の登録情報または取引情報が識別できない場合に限ります）として利用する場合はこの限りではありません。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>①</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>個別のリソース提供取引の相手方の当事者情報</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>②</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>個別のリソース提供取引に係る製品または役務の価格及び数量</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>③</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>その他、開示が不適切であると当社が合理的に判断する情報</span>
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>3.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >前項のほか、当社は、登録会員の登録情報及び過去の取引情報を、以下に定める他の登録会員に開示することはありません。但し、本規約に定める範囲内で、統計情報または分析結果（特定の登録情報または取引情報が識別できない場合に限ります）として利用する場合はこの限りではありません。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>①</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>当該登録会員が開示することを拒否した第三者</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>②</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>その他、開示が不適切であると当社が合理的に判断する他の登録会員</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>③</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>当社は、</span><span>(i)</span><span>第</span><span>10</span><span>条第</span
      ><span>1</span
      ><span
        >項に基づき収集し、管理及び保管する登録会員のリソース提供取引の取引情報（当社と登録会員との間でリソース取引契約が締結される場合に限ります）及び</span
      ><span>(ii)</span><span>第</span><span>10</span><span>条第</span><span>4</span
      ><span
        >項に基づき収集し、管理及び保管する登録会員のリソース提供取引に係るリソース稼働データ（当社と登録会員との間でリソース取引契約が締結される場合を含みます）を、本条第</span
      ><span>1</span
      ><span
        >項の目的達成の範囲で開示できるものとします。但し、当該登録会員が承諾しない旨を当社所定の方法で事前に通知した場合はこの限りではありません。</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>12</span><span>条（利益相反）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >登録会員は、当社が、パートナーまたは他の登録会員に対し、本サービスの一環として、リソース取引契約に基づきアドバイスその他のサービスを提供する場合があることを理解し、当社の定める基準に従ってこれを行う限り、当該パートナーまたは他の登録会員に対するサービスの提供につき、予め同意するものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >登録会員は、パートナーとの間でリソース提供取引を行うにあたり、前項の規定及びリソース取引契約に基づき、当社が、パートナーを代理する場合があること（販売代理を含むがこれに限られません）に予め同意します。</span
        >
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>13</span><span>条（知的財産権）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        class="ListParagraph"
        style="
          margin-left: 26.33pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 9.12pt;
        "
      >
        <span
          >本サービスおよび本サービスの利用により生じる統計情報または分析結果に関する知的財産権等は全て当社</span
        ><span>または当社にライセンスを許諾している者</span>
        <span
          >に帰属しており、本サービスに関して生じた発明、考案または創作に関する知的財産権等は、別途定めがある場合を除き、全て当社または当社にライセンスを許諾している者に帰属するものとします。サービス利用契約に基づく登録会員に対する本サービスの提供は、別途定めがある場合を除き、当社または当社にライセンスを許諾している者の当該知的財産権等の譲渡、移転、使用許諾を意味するものではありません。</span
        >
      </li>
      <li
        class="ListParagraph"
        style="
          margin-left: 26.33pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 9.12pt;
        "
      >
        <span
          >前項に関わらず、本サービスのコンテンツのうち、当社が利用者に対して利用することを明示的に許諾しているものについては、利用者は許諾の範囲内において自由に利用することができ、その限りにおいて当社は著作者人格権を行使しないものとします。</span
        >
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: center; line-height: 75%">
      <span>第</span><span>5</span><span>章 登録会員の義務</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>14</span><span>条（パスワード及び会員</span><span>ID</span
      ><span>の管理）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>登録会員は、本サービスの利用にあたって提供される会員</span><span>ID</span
        ><span
          >及びパスワードを、自己の責任において適切に管理するものとし、これを第三者に開示し、または利用させてはならないものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>当社は、会員</span><span>ID</span
        ><span
          >とパスワードの一致によって認証を行った後に行われた本サービスの利用行為については、全て登録会員に帰属するものとみなすことができます。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>会員</span><span>ID</span
        ><span
          >またはパスワードの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録会員が負うものとし、当社は一切の責任を負いません。</span
        >
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>15</span><span>条（禁止事項）</span>
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span
        >登録会員は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為を行ってはならないものとします。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>①</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>本規約に違反する行為</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>②</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>法令に違反する行為</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>③</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>本サービスの運営を妨害するおそれのある行為</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>④</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>当社、他の登録会員またはその他の第三者の権利または利益を侵害する行為</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑤</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>当社による本サービスの提供を妨害する行為</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑥</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>本サービスのネットワークまたはシステム等に過度な負荷をかける行為</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑦</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >当社のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑧</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >本サービスのシステム解析、探知、デコンパイル、逆アセンブル、リバースエンジニアリングその他本サービスに係るコンピューター・システムのソースコードを入手しようとする行為</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑨</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>反社会的勢力との何らかの関係を有する行為</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑩</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>その他、当社が不適切と判断する行為</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>16</span><span>条（反社会的勢力の排除）</span>
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>1.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >登録会員及び当社は、互いに、自らが、反社会的勢力（暴力団、暴力団員、暴力団員でなくなった時から</span
      ><span>5</span
      ><span
        >年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者をいいます。以下同じ）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>①</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>反社会的勢力が経営を支配していると認められる関係を有すること</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>②</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>反社会的勢力が経営に実質的に関与していると認められる関係を有すること</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>③</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >自らまたは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってする等、不当に反社会的勢力を利用していると認められる関係を有すること</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>④</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >反社会的勢力に対して資金等を提供し、または便宜を供与する等の関与をしていると認められる関係を有すること</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑤</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >役員または経営に実質的に関与している者が反社会的勢力と社会的に非難されるべき関係を有すること</span
      >
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>2.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >登録会員及び当社は、互いに、自らまたは第三者を利用して次の各号に該当する行為を行わないことを確約します。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>①</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>暴力的な要求行為</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>②</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>法的な責任を超えた不当な要求行為</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>③</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>取引に関して、脅迫的な言動をし、または暴力を用いる行為</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>④</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑤</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>その他前各号に準ずる行為</span>
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>3.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>登録会員及び当社は、相手方が反社会的勢力若しくは本条第</span><span>1</span
      ><span>項各号のいずれかに該当することが判明し、若しくは本条第</span><span>2</span
      ><span>項各号のいずれかに該当する行為をしたとき、または相手方の本条第</span><span>1</span
      ><span
        >項に基づく表明確約に関して真実との相違があり若しくは真実と異なる事態が生じたことが判明したときは、何らの催告をせずサービス利用契約及びリソース取引契約を解除することができるものとし、相手方はこれにより生じた損害について何らの請求をしないものとします。</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: center; line-height: 75%">
      <span>第</span><span>6</span><span>章 利用期間</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>17</span><span>条（サービス利用契約期間）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>サービス利用契約の契約期間は、本サービスの利用開始日から</span><span>1</span
        ><span>年間とします。</span>
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span>前項の規定にかかわらず、サービス利用契約の契約満了日の</span><span>3</span
        ><span
          >ヶ月前までに、登録会員から当社に対して、当社所定の方法で解約の申し込みがなかった場合には、サービス利用契約の契約満了日の翌日を更新日として、同一の内容・条件にてサービス利用契約が更新されるものとし、以後も同様とします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >前各項の定めにかかわらず、個別取引契約に別段の定めがある場合にはこれに従うものとします。</span
        >
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: center; line-height: 75%">
      <span>第</span><span>7</span><span>章 本サービスの停止、終了等</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>18</span><span>条（本サービスの中断、停止、終了等）</span>
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>1.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >当社は、以下のいずれかに該当する場合には、利用者に事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>①</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>本サービスに係るシステムの点検または保守作業を定時にまたは緊急に行う場合</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>②</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >地震、落雷、停電、火災、天災等の不可抗力により本サービスの提供ができなくなった場合</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>③</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>通信回線等が事故により停止した場合</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>④</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>その他当社が停止または中断を必要と判断した場合</span>
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>2.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >当社は、当社の都合により何時でも、利用者に事前に通知することなく、本サービスの全部または一部につき、その内容を変更しまたは提供を終了することができるものとします。</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>19</span><span>条（登録会員による解除）</span>
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span>登録会員は、サービス利用契約の期間中、解除しようとする日の</span><span>3</span
      ><span
        >ヶ月前までに、当社所定の方法で当社に通知することにより、サービス利用契約を解除し、自己の登録会員としての登録を抹消することができます。但し、登録会員が既に支払った利用料については、その理由の如何を問わず、これを返還しないものとします。</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>20</span><span>条（当社による会員登録の抹消）</span>
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span
        >当社は、登録会員が、以下の各号のいずれかの事由に該当すると判断した場合は、事前に通知等することなく、サービス利用契約の全部または一部を解除し、または会員登録を抹消することができます。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>①</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>サービス利用契約のいずれかの条項に違反し、書面による催告をしたにもかかわらず、</span
      ><span>30</span><span>日を経過しても当該違反が是正されない場合</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>②</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>登録事項に虚偽の事実があることが判明した場合</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>③</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >支払停止若しくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>④</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >その他、当社が登録会員としての登録、またはサービス利用契約の継続を適当でないと判断した場合</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: center; line-height: 75%">
      <span>第</span><span>8</span><span>章 責任</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>21</span><span>条（登録会員の損害賠償責任）</span>
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span
        >登録会員が、サービス利用契約のいずれかの条項に違反した場合、登録会員は、これにより当社が被った、損害、損失または費用等を賠償するものとします。なお、本条に基づき損害賠償認められる場合であっても、当社のサービス利用契約に基づき認められる他の権利（解除を含むがこれに限られません）は制限されないものとします。</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>22</span><span>条（不保証）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >当社は、本サービスの一環として提供される取引情報、統計情報、分析結果及び他の登録会員の登録情報の正確性、完全性、安全性、有用性及び特定目的適合性について、何ら保証をするものではありません。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >当社は、個別取引契約に別途定めがある場合を除き、パートナーの提供するリソースの一切につき、何ら保証するものではありません。</span
        >
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>23</span><span>条（免責）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >登録会員は、自己の判断と責任に基づき本サービスを利用するものとし、本サービスの利用及びその結果について、一切の責任を負うものとします。当社は、当社の故意または重過失に基づく場合を除き、当社による本サービスの提供に起因しまたはこれに関連して、登録会員が被った損害につき、賠償する責任を一切負わないものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >当社の責めに帰すべき事由に基づき、本サービスを通じたリソース提供取引により、登録会員に損害が生じた場合、当該登録会員が被った損害に対する当社の賠償責任は、当社の故意または重過失に基づく場合を除き、個別取引契約に定めるサービス利用料金の支払済み料金のうち直近</span
        ><span>1</span><span>年分の金額を上限とします。</span>
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >本サービスを通じたリソース提供取引に関連して、登録会員と他の登録会員または第三者との間において生じたトラブル、紛争等については、当社は一切責任を負わないものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >当社は、天災、法令の改廃、官公庁による指導、疫病の発生、その他の当社の不可抗力事由に起因してまたはこれに関連して登録会員が被った損害につき、賠償する責任を一切負わないものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >当社が何らかの損害賠償責任を負う場合、当社は、登録会員に直接生じた通常損害に限り責任を負うものとします。当社は、いかなる場合であっても、登録会員に生じた間接損害、特別損害、事業機会の喪失及び逸失利益にかかる損害については、賠償する責任を一切負わないものとします。</span
        >
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: center; line-height: 75%">
      <span>第</span><span>9</span><span>章 雑則</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>24</span><span>条（秘密保持）</span>
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>1.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >登録会員及び当社は、本規約において別途認められる場合を除き、本サービスに関連して知りえた情報（以下「秘密情報」といい、秘密情報を開示した者を「開示者」、開示を受けた者を「被開示者」といいます）を厳に秘密として保持し、別途定めがある場合または開示者の事前の書面による承諾がある場合を除き、第三者に開示、提供、漏えいし、または当該開示の目的以外の目的に使用してはならないものとします。</span
      >
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>2.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >前項の規定にかかわらず、次の各号のいずれかに該当する情報は、秘密情報に当たらないものとします。</span
      >
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>①</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>提供または開示の時点で既に被開示者が保有していた情報</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>②</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>秘密情報によらず被開示者が独自に開発した情報</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>③</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>提供または開示の時点で公知の情報</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>④</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>提供後または開示後に被開示者の責に帰すべき事由によらずに公知となった情報</span>
    </p>
    <p
      style="
        margin-left: 76pt;
        margin-bottom: 8pt;
        text-indent: -22pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>⑤</span
        ><span style="width: 16.38pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>正当な権利を有する第三者から秘密保持義務を負うことなく提供または開示された情報</span>
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>3.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>第</span><span>1</span
      ><span
        >項の規定にかかわらず、被開示者は、自己の役員、従業員若しくは業務委託先（別途業務委託契約を締結し、従業員と同等の業務を受託する者で、本サービスに関連する業務の履行に従事する者をいいます）、または、弁護士、公認会計士、税理士等法令上秘密保持義務を負う専門家に対し必要がある場合には、本条に定めるのと同等の秘密保持義務を負わせることを条件として、合理的で必要な範囲内で、これらの者に対して当該秘密情報を開示することができるものとします。</span
      >
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>4.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span>第</span><span>1</span
      ><span
        >項の規定にかかわらず、被開示者は、法令上の強制力を伴う開示請求が公的機関よりなされた場合は、その請求に応じるため必要な範囲に限り、開示者へ遅滞なく通知を行うことを条件として秘密情報を開示することができるものとします。</span
      >
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>5.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >本規約において別途認められる場合を除き、被開示者は、サービス利用契約が終了した場合、開示者の指示に従い秘密情報を遅滞なく返還または破棄しなければならないものとします。</span
      >
    </p>
    <p
      style="
        margin-left: 36pt;
        margin-bottom: 8pt;
        text-indent: -18pt;
        text-align: left;
        line-height: 75%;
        widows: 2;
        orphans: 2;
      "
    >
      <span
        ><span>6.</span
        ><span style="width: 10.12pt; font: 7pt 'Times New Roman'; display: inline-block"
          >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
        </span></span
      ><span
        >本条に規定する義務は、別途定めがある場合を除き、サービス利用契約の期間中及びサービス利用契約終了後も存続するものとします。</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>25</span><span>条（本規約の変更）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >当社は、当社の裁量で、本規約を随時変更できるものとします。当社が本規約を変更する場合、本規約を変更する旨、変更後の本規約の内容及び変更の効力発生時期を、当社のウェブサイトへの掲載その他の適切な方法により周知し、または登録会員に通知します。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >当該変更内容のウェブサイトへの掲載その他の通知後、登録会員が本サービスを利用した場合または当社の定める期間内に登録抹消の手続をとらなかった場合、登録会員は、本規約の変更に同意したものとみなします。</span
        >
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>26</span><span>条（通知）</span>
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span
        >登録会員から当社に対する通知、及び本規約の変更に関する通知その他当社から登録会員に対する通知は、当社の定める方法で行うものとします。</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>27</span><span>条（契約上の地位の譲渡等）</span>
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span
        >登録会員は、当社の書面による事前の承諾なく、サービス利用契約上の地位または本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>28</span><span>条（分離可能性）</span>
    </p>
    <p style="margin-left: 36pt; margin-bottom: 8pt; line-height: 75%">
      <span
        >本規約のいずれかの条項またはその一部が、適用法令等により無効または執行不能と判断された場合であっても、無効または執行不能と判断された規定の残りの部分は、引き続き効力を有するものとします。</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>29</span><span>条（準拠法及び管轄裁判所）</span>
    </p>
    <ol type="1" style="margin: 0; padding-left: 0">
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >本規約及びサービス利用契約は日本法を準拠法とし、これに従って解釈されるものとします。</span
        >
      </li>
      <li
        style="
          margin-left: 30.88pt;
          margin-bottom: 8pt;
          text-align: left;
          line-height: 75%;
          widows: 2;
          orphans: 2;
          padding-left: 5.12pt;
        "
      >
        <span
          >本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</span
        >
      </li>
    </ol>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: center; line-height: 75%">
      <span>附則 オプションサービスについて</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>30</span><span>条（定義）</span>
    </p>
    <p style="margin-left: 31.5pt; margin-bottom: 8pt; text-indent: -21pt; line-height: 75%">
      <span>１．「スポットコンサルティング」とは、チケット制相談サービスを指し、</span
      ><span>DX</span><span>ソリューション選定にまつわる相談事項を</span><span>1</span
      ><span>時間単位でご相談いただけるサービスです。</span><span>RC</span
      ><span>エントリー及び</span><span>RC</span
      ><span>スタンダードは別途申込みをすることで利用することができます。</span><span>RC</span
      ><span>ビジネスは、プランに含まれていますので、別途申込みは不要です。</span>
    </p>
    <p style="margin-left: 31.5pt; margin-bottom: 8pt; text-indent: -21pt; line-height: 75%">
      <span>２．「DX plus」とは、</span><span>DX</span
      ><span>企画支援機能を指し、企業の課題を把握や、課題を解決する</span><span>IT</span
      ><span>ソリューションの比較情報を閲覧することができるサービスです。</span><span>RC</span
      ><span>スタンダード及び</span><span>RC</span
      ><span>ビジネスのプランに含まれていますので、別途申込みは不要です。ビジター及び</span
      ><span>RC</span><span>エントリーが本オプションを希望する場合は、</span><span>RC</span
      ><span>スタンダード以上へプランのアップグレードが必要になります。</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>32</span><span>条（スポットコンサルティングの利用）</span>
    </p>
    <p style="margin-left: 31.5pt; margin-bottom: 8pt; text-indent: -21pt; line-height: 75%">
      <span> １．スポットコンサルティングの利用に必要なチケットは</span><span>1</span
      ><span
        >枚単位で購入することができます。チケットの購入方法、料金及びチケット残数は別途当社担当者までお問合わせください。</span
      >
    </p>
    <p style="margin-left: 31.5pt; margin-bottom: 8pt; text-indent: -21pt; line-height: 75%">
      <span> ２．チケットは</span><span>1</span><span>枚で</span><span>1</span
      ><span>時間分ご相談いただけますが、端数時間分は繰り上げになります。</span>
    </p>
    <p style="margin-left: 31.5pt; margin-bottom: 8pt; text-indent: -21pt; line-height: 75%">
      <span> ３．チケットの有効期限は発行日から</span><span>6</span
      ><span
        >か月後の末日までとします。ただし、有効期間内のチケットをお持ちの状態でチケットを追加購入した場合、すべてのチケットの有効期間は、追加購入いただいたチケット発行日から</span
      ><span>6</span
      ><span
        >か月後の末日までに延長されます。なお、ご購入いただいたチケットは理由の如何を問わず、払い戻しは致しません。</span
      >
    </p>
    <p style="margin-left: 31.5pt; margin-bottom: 8pt; text-indent: -21pt; line-height: 75%">
      <span>
        ４．チケットはご購入された会員のみ有効です。他の会員への譲渡・転売することはできません。</span
      >
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>&#xa0;</span>
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>33</span><span>条（DX plusの利用）</span>
    </p>
    <p style="margin-left: 31.5pt; margin-bottom: 8pt; text-indent: -21pt; line-height: 75%">
      <span>
        １．DX
        plusは、利用者自身が組織全体の課題を構造的に理解し、目的に合わせて最適なソリューションを検討・導入するための支援ツールです。そのため、DX
        plusを利用して、利用者以外の法人（利用者の親会社、子会社、関係会社等を含む）もしくは個人（以下当該法人および個人をあわせて「第三者」といいます）に対して営業ツールとして利用する等、目的外の利用をすることはできません。
      </span>
    </p>
    <p style="margin-left: 31.5pt; margin-bottom: 8pt; text-indent: -21pt; line-height: 75%">
      <span> ２．第</span><span>30</span><span>条</span><span>2</span><span>項記載の通り、</span
      ><span>DX plusは</span><span>RC</span
      ><span>スタンダード以上のプラン申込が必要になります。プラン変更については第</span
      ><span>7</span><span>条</span><span>2</span><span>項の定めによるものとします。</span>
    </p>
    <p style="margin-left: 31.5pt; margin-bottom: 8pt; text-indent: -21pt; line-height: 75%">
      <span>
        ２．利用者は、利用者自らまたは第三者に対して、如何なる方法によっても、DX
        plusに関し、複写、複製、転載、引用、配信（ネットワークに接続されたサーバへのアップロードを含みます）、編集、翻案、改変、改竄、翻訳、第三者への開示等をしてはならないものとします。
      </span>
    </p>
    <p style="margin-left: 28.35pt; margin-bottom: 8pt; text-indent: -17.85pt; line-height: 75%">
      <span>
        ３．利用者は、利用者自らまたは第三者をして、DX
        plusと同一または類似したサービスを作成・提供してはならないものとします。
      </span>
      <br />
    </p>
    <p style="margin-bottom: 8pt; line-height: 75%">
      <span>第</span><span>34</span><span>条（適用）</span>
    </p>
    <p style="margin-left: 31.5pt; margin-bottom: 8pt; text-indent: -31.5pt; line-height: 75%">
      <span>
        オプションサービスについても本サービスと同様、本規約及び個別取引契約の定めが適用されるものとします。</span
      >
    </p>
    <p style="margin-bottom: 8pt; text-align: right; line-height: 75%">
      <span>2020</span><span>年</span><span>7</span><span>月制定 第</span><span>1</span
      ><span>版</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: right; line-height: 75%">
      <span>2023</span><span>年</span><span>3</span><span>月改定 第</span><span>2</span
      ><span>版</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: right; line-height: 75%">
      <span>2024</span><span>年</span><span>2</span><span>月改定 第</span><span>3</span
      ><span>版</span>
    </p>
    <p style="margin-bottom: 8pt; text-align: right; line-height: 75%">
      <span>2024</span><span>年</span><span>7</span><span>月改定 第</span><span>4</span
      ><span>版</span>
    </p>
    <p><span>&#xa0;</span></p>
    <p><span>&#xa0;</span></p>
    <p><span>&#xa0;</span></p>
    <p><span>&#xa0;</span></p>
    <p><span>&#xa0;</span></p>
    <p><span>機能比較</span></p>
    <div class="table-wrapper">
      <table style="border-collapse: collapse; border: none">
        <tr style="height: 28pt">
          <td
            style="
              width: 118.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p class="MsoNormal">&nbsp;</p>
          </td>
          <td
            style="
              width: 136.5pt;
              border: solid black 1pt;
              border-left: none;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 8.5pt; line-height: 115%"
                >RC <span lang="ZH-CN">エントリー</span></span
              >
            </p>
          </td>
          <td
            style="
              width: 133.5pt;
              border: solid black 1pt;
              border-left: none;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 8.5pt; line-height: 115%"
                >RC <span lang="ZH-CN">スタンダード</span></span
              >
            </p>
          </td>
          <td
            style="
              width: 135.75pt;
              border: solid black 1pt;
              border-left: none;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 8.5pt; line-height: 115%"
                >RC <span lang="ZH-CN">ビジネス</span></span
              >
            </p>
          </td>
        </tr>
        <tr style="height: 28pt">
          <td
            style="
              width: 118.5pt;
              border: solid black 1pt;
              border-top: none;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span lang="ZH-CN" style="font-size: 8.5pt; line-height: 115%">ツール情報閲覧</span>
            </p>
          </td>
          <td
            style="
              width: 136.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
          <td
            style="
              width: 133.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
          <td
            style="
              width: 135.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
        </tr>
        <tr style="height: 27.25pt">
          <td
            style="
              width: 118.5pt;
              border: solid black 1pt;
              border-top: none;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 27.25pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span lang="ZH-CN" style="font-size: 8.5pt; line-height: 115%"
                >目的別ノウハウ閲覧</span
              >
            </p>
          </td>
          <td
            style="
              width: 136.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 27.25pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
          <td
            style="
              width: 133.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 27.25pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
          <td
            style="
              width: 135.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 27.25pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
        </tr>
        <tr style="height: 28pt">
          <td
            style="
              width: 118.5pt;
              border: solid black 1pt;
              border-top: none;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span lang="ZH-CN" style="font-size: 8.5pt; line-height: 115%">見積確認</span>
            </p>
          </td>
          <td
            style="
              width: 136.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
          <td
            style="
              width: 133.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
          <td
            style="
              width: 135.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
        </tr>
        <tr style="height: 28pt">
          <td
            style="
              width: 118.5pt;
              border: solid black 1pt;
              border-top: none;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 8.5pt; line-height: 115%">DX plus利用</span>
            </p>
          </td>
          <td
            style="
              width: 136.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">×</span>
            </p>
          </td>
          <td
            style="
              width: 133.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span lang="ZH-CN" style="font-size: 10pt; line-height: 115%">無制限</span>
            </p>
          </td>
          <td
            style="
              width: 135.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span lang="ZH-CN" style="font-size: 10pt; line-height: 115%">無制限</span>
            </p>
          </td>
        </tr>
        <tr style="height: 27.25pt">
          <td
            style="
              width: 118.5pt;
              border: solid black 1pt;
              border-top: none;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 27.25pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span lang="ZH-CN" style="font-size: 8.5pt; line-height: 115%"
                >アカウント案件連携</span
              >
            </p>
          </td>
          <td
            style="
              width: 136.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 27.25pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">×</span>
            </p>
          </td>
          <td
            style="
              width: 133.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 27.25pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
          <td
            style="
              width: 135.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 27.25pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
        </tr>
        <tr style="height: 28pt">
          <td
            style="
              width: 118.5pt;
              border: solid black 1pt;
              border-top: none;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span lang="ZH-CN" style="font-size: 8.5pt; line-height: 115%"
                >スポットコンサルタント</span
              >
            </p>
          </td>
          <td
            style="
              width: 136.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span lang="ZH-CN" style="font-size: 10pt; line-height: 115%">別途有償にて提供</span>
            </p>
          </td>
          <td
            style="
              width: 133.5pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
          <td
            style="
              width: 135.75pt;
              border-top: none;
              border-left: none;
              border-bottom: solid black 1pt;
              border-right: solid black 1pt;
              background: white;
              padding: 5pt 5pt 5pt 5pt;
              height: 28pt;
            "
          >
            <p style="text-align: center; line-height: 115%">
              <span style="font-size: 10pt; line-height: 115%">⚪︎</span>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <p style="text-align: right"><span>&#xa0;</span></p>
    <p><span>支払い条件：</span></p>
    <p>
      <span>月額利用費用については、申込日が属する月の翌月</span><span>1</span><span>日から</span
      ><span>1</span
      ><span>年間の利用費用を、申込月の末日で請求し、翌月末の現金一括払いとします。</span>
    </p>
    <p>
      <span
        >スポットコンサルタント費用については、申込月の月末請求、翌月末の現金一括払いとします。</span
      >
    </p>
    <p class="closing"><span>以上</span></p>
    <p style="text-align: right"><span>&#xa0;</span></p>
    <p style="text-align: right"><span>&#xa0;</span></p>
    <div style="clear: both">
      <p class="Footer"><span>&#xa0;</span></p>
      <p class="Footer"><span>&#xa0;</span></p>
    </div>
  </div>
</template>

<style scoped>
* {
  line-height: 18px;
}
.terms-wrapper {
  height: 70vh;
  padding-right: 20px;
  overflow-y: scroll;
}
.table-wrapper {
  display: flex;
  justify-content: center;
}
.closing {
  text-align: right;
}
</style>
