<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { useAuthStore } from '@/stores/authStore'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import { DArrowRight } from '@element-plus/icons-vue'
import TermsAndConditions from '@/components/TermsAndConditions.vue'

// props
interface Props {
  redirect?: string
}
const props = withDefaults(defineProps<Props>(), {
  redirect: ''
})

// init
let loginJson = ref({
  email: '',
  password: ''
})
const authStore = useAuthStore()
const router = useRouter()

// Loginに飛ばされた時点で強制ログアウトする
authStore.logoutOnLoginPage()

// login
const isLoggingIn = ref(false)
function login() {
  // validation
  const json = { ...loginJson.value }

  isLoggingIn.value = true
  // login
  authStore
    .login(json)
    .then(() => {
      next()
    })
    .catch((err) => {
      console.log(err)
    })
    .finally(() => {
      isLoggingIn.value = false
    })
}
function next() {
  if (props.redirect !== '') {
    router.push(props.redirect)
  } else {
    router.push(`/projects`)
  }
}

// forget password
const isDialogVisible = ref(false)
const dialogData = ref({
  email: ''
})
function openDialog() {
  dialogData.value.email = ''
  isDialogVisible.value = true
}
const isSubmittingForgetPassword = ref(false)
function submitForgetPassword() {
  if (!dialogData.value.email.length) {
    _ElMessage({ type: 'error', message: 'メールアドレスを入力してください' })
    return
  }
  isSubmittingForgetPassword.value = true
  authStore
    .forgetPassword(dialogData.value.email)
    .then(() => {
      _ElMessage({ type: 'success', message: 'パスワード再設定メールをお送りしました' })
      isDialogVisible.value = false
    })
    .catch((err) => {
      console.log(err)
    })
    .finally(() => {
      isSubmittingForgetPassword.value = false
    })
}

// rc
function openRcContact() {
  let w = window.open()
  if (w) {
    w.opener = null
    w.location = 'https://resource-cloud.jp/contact'
  }
}

// terms
const isTermsVisible = ref(false)
</script>

<template>
  <div class="container">
    <!-- dialog -->
    <el-dialog
      :close-on-press-escape="false"
      v-model="isDialogVisible"
      top="10vh"
      width="50%"
      class="ix-dialog"
    >
      <template #header>パスワード再設定リクエスト</template>
      <el-form :model="dialogData" label-position="left" label-width="120px" @submit.prevent>
        <el-form-item label="Email">
          <el-input v-model="dialogData.email" placeholder="example@industrial-x.jp" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span>
          <el-button link @click="isDialogVisible = false">閉じる</el-button>
          <el-button :loading="isSubmittingForgetPassword" @click="submitForgetPassword"
            >申請する</el-button
          >
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :close-on-press-escape="false"
      v-model="isTermsVisible"
      top="10vh"
      width="800px"
      class="ix-dialog"
    >
      <div>
        <TermsAndConditions />
      </div>
    </el-dialog>
    <!-- template -->
    <div class="cover">
      <div class="container-left">
        <div class="service-copy-wrapper">
          <div class="service-target">【DX推進者向け】</div>
          <div class="service-prefix">DX推進企画/提案支援ツール</div>
          <div class="service-title">
            <img
              src="@/assets/logo_with_service_title.png"
              alt="Resource Cloud DX plus"
              width="150px"
            />
          </div>
          <div class="inquiry-btn" @click="openRcContact()">お問い合わせはこちら</div>
        </div>
      </div>
      <div class="container-right">
        <div id="login">
          <div class="logo">ログインはこちら</div>
          <div class="form-wrapper">
            <el-form label-position="top">
              <el-form-item label="メールアドレス">
                <el-input v-model="loginJson.email" placeholder="example@industrial-x.jp" />
              </el-form-item>
              <el-form-item label="パスワード">
                <el-input
                  v-model="loginJson.password"
                  type="Password"
                  placeholder="Password (半角8~24文字, 記号英数字)"
                  @keyup.enter="login()"
                />
              </el-form-item>
            </el-form>
          </div>
          <div class="actions">
            <el-button class="submit" :loading="isLoggingIn" @click="login">ログイン</el-button>
          </div>
          <div class="forget-password">
            <div class="forget-password-text" @click="openDialog()">
              パスワードを忘れた方はこちら
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="service-description">
        DX plusは業種と目的を選択すると、<br />現状の課題と解決方法を提示。
      </div>
      <div class="service-description">
        DX 課題の解決方法から具体的なソリューション候補一覧まで<br />一括で作成できるDX企画書制作ツールです。
      </div>
      <div class="service-image-wrapper">
        <div class="service-image-item">
          <img src="@/assets/ic-brain.png" />
          <div class="service-image-description">課題や目的を<br />入力</div>
        </div>
        <div class="arrow-icon">
          <DArrowRight />
        </div>
        <div class="service-image-item small">
          <img src="@/assets/ic-folder-open.png" />
          <div class="service-image-description">DX企画書が<br />すぐに完成</div>
        </div>
        <div class="arrow-icon">
          <DArrowRight />
        </div>
        <div class="service-image-item small">
          <img src="@/assets/ic-presentation-check.png" />
          <div class="service-image-description">そのまま提案に<br />活用</div>
        </div>
        <div class="arrow-icon">
          <DArrowRight />
        </div>
        <div class="service-image-item">
          <img src="@/assets/ic-handshake.png" />
          <div class="service-image-description">すぐ導入に<br />着手できる</div>
        </div>
      </div>
      <div class="service-benefit">
        <div class="subheader">DX plusを活用するメリット</div>
        <div class="benefit">
          <div class="benefit-tag">メリット1</div>
          <div class="benefit-description">
            DX推進ノウハウがなくても、DX推進の企画書を作成できる！
          </div>
        </div>
        <div class="benefit">
          <div class="benefit-tag">メリット2</div>
          <div class="benefit-description">
            提案ソリューションまで提示されるため、自分でサービスを調べる必要なし！
          </div>
        </div>
        <div class="benefit">
          <div class="benefit-tag">メリット3</div>
          <div class="benefit-description">
            企業の課題やあるべき姿から提案するので、納得度の高い提案が可能！
          </div>
        </div>
      </div>
      <div class="service-flow">
        <div class="service-step">
          <div class="step-header">
            <div class="step-prefix">Step</div>
            <div class="step-num">１</div>
            <div class="step-title">業種/部門を選択し、現状課題把握</div>
          </div>
          <div class="step-note">
            業種や部門を選択するだけで現状課題が表示されます。<br />表示された情報を元に検討することで、スピーディーに課題状況を把握できます。
          </div>
        </div>
        <div class="service-step">
          <div class="step-header">
            <div class="step-prefix">Step</div>
            <div class="step-num">２</div>
            <div class="step-title">ありたい姿の確認</div>
          </div>
          <div class="step-note">
            目的に応じて仮説として将来的にありたい姿が表示されます。ありたい姿の解像度をあげましょう。
          </div>
        </div>
        <div class="service-step">
          <div class="step-header">
            <div class="step-prefix">Step</div>
            <div class="step-num">３</div>
            <div class="step-title">優先したい施策を検討</div>
          </div>
          <div class="step-note">
            さまざまな施策候補の中から優先する施策を選ぶことで、<br />ありたい姿を実現するためのソリューションを絞り込みます。
          </div>
        </div>
        <div class="service-step">
          <div class="step-header">
            <div class="step-prefix">Step</div>
            <div class="step-num">４</div>
            <div class="step-title">ソリューションと商材の検討</div>
          </div>
          <div class="step-note">
            ソリューション候補と商材が表示されます。<br />選定ポイントや概算も見積もり事例を参考にしながら商材を選定しましょう。
          </div>
        </div>
        <div class="service-step final">
          <div class="step-header">
            <div class="step-prefix">Step</div>
            <div class="step-num">５</div>
            <div class="step-title">具体的アクションの実施（AI機能搭載）</div>
          </div>
          <div class="step-note">
            検討してきた内容を元に提案活動を行いましょう。<br />お困りごとがある場合は、AIもしくは弊社に相談可能です。
          </div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <div class="terms">
        <el-button link @click="isTermsVisible = true" type="primary"> 利用規約 </el-button>
      </div>
      <div>Copyright 2024 marketing@industrial-x.jp</div>
    </footer>
  </div>
</template>
<style scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* cover */
.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 600px;
  background-image: linear-gradient(
    90deg,
    rgba(233, 240, 250, 1),
    40%,
    v-bind('colors.service.darkBlue')
  );
  .container-left {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    align-items: center;
    background-color: #eeeff2;
    position: relative;
    .service-copy-wrapper {
      width: 40%;
      min-width: 300px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      z-index: 1;
      .service-target {
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
      }
      .service-prefix {
        font-size: 20px;
        line-height: 20px;
        font-weight: bold;
      }
      .service-title {
        margin-top: 8px;
        font-size: 40px;
        line-height: 40px;
        font-weight: bold;
      }
      .inquiry-btn {
        margin-top: 24px;
        display: inline-block;
        width: 180px;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        padding: 8px 16px;
        border-radius: 16px;
        background-color: v-bind('colors.service.accent');
        color: v-bind('colors.text.white');
        cursor: pointer;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: -35%;
      left: calc(50% + 100px);
      width: 80%;
      height: 120%;
      background-color: #eeeff2;
      transform-origin: 0% 0%;
      transform: rotate(18deg);
      z-index: 0;
    }
  }
  .container-right {
    width: 60%;
    display: flex;
    padding-left: 20%;
    align-items: center;
  }
}

/* login */
#login {
  display: inline-block;
  width: 424px;
  height: 480px;
  box-sizing: border-box;
  padding: 60px 40px 32px;
  background-color: v-bind('colors.bg.white');
  border-radius: 4px;
  box-shadow: v-bind('colors.shadow.card');
  .submit {
    color: #fff; /* textWhite */
    background-color: #212121; /* bgGray08 */
  }
}
.logo {
  text-align: center;
  color: v-bind('colors.text.black');
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
}
.form-wrapper {
  margin-top: 8px;
  box-sizing: border-box;
  padding-top: 32px;
  padding-bottom: 32px;
}
.el-form-item {
  margin-bottom: 0;
}
.el-form-item + .el-form-item {
  margin-top: 12px;
}
#login :deep(.el-input__inner) {
  height: 36px;
  line-height: 36px;
}
#login :deep(.el-form-item__label) {
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  color: v-bind('colors.text.lighter');
}
#login :deep(.el-form--label-top .el-form-item__label) {
  padding: 0;
}
#login :deep(.el-input__inner:focus) {
  border-color: v-bind('colors.border.active');
}
#login :deep(.el-input__inner) {
  font-family: inherit;
  color: v-bind('colors.text.base');
}
.actions {
  text-align: center;
}
.forget-password {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}
.forget-password-text {
  height: 40px;
  line-height: 40px;
  color: v-bind('colors.text.lighter2');
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
}

/* content */
.content {
  width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-top: 32px;
  background-color: v-bind('colors.bg.gray01');
  .service-description {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 1.5;
  }
  .service-image-wrapper {
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: center;
    align-items: center;
    padding: 12px 32px 20px;
    background-color: #eeeff2;
    width: 100%;
    .service-image-item {
      display: flex;
      flex-basis: 20%;
      flex-shrink: 0;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      justify-content: center;
      &.small {
        padding: 8px;
        gap: 20px;
        img {
          margin-top: 8px;
        }
      }
      img {
        width: 70%;
        height: auto;
      }
      .service-image-description {
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        text-align: center;
      }
    }
    .arrow-icon {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      color: v-bind('colors.text.base');
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .service-benefit {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .subheader {
      font-size: 20px;
      line-height: 20px;
      font-weight: bold;
      text-align: center;
      &::after {
        content: '';
        display: block;
        width: 40px;
        height: 2px;
        margin: 8px auto;
        background-color: v-bind('colors.service.accent');
      }
    }
    .benefit {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      height: 48px;
      padding: 0 24px;
      border: 1px solid v-bind('colors.border.base');
      border-bottom: 1px solid v-bind('colors.service.secondary');
      .benefit-tag {
        font-size: 14px;
        font-weight: bold;
        width: 100px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background-color: v-bind('colors.service.darkBlue');
        color: v-bind('colors.text.white');
      }
      .benefit-description {
        font-size: 14px;
        font-weight: bold;
        color: v-bind('colors.text.base');
      }
    }
  }
  .service-flow {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 40px;
    padding-bottom: 32px;
    .service-step {
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: auto;
      padding: 12px 24px;
      border: 2px solid v-bind('colors.service.darkBlue');
      .step-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid v-bind('colors.service.secondary');
        gap: 6px;
        .step-prefix {
          font-size: 20px;
          font-weight: bold;
        }
        .step-num {
          font-size: 14px;
          font-weight: bold;
          background-color: v-bind('colors.service.darkBlue');
          color: v-bind('colors.text.white');
          border-radius: 100%;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
        }
        .step-title {
          padding-left: 12px;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .step-note {
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
        padding-bottom: 4px;
      }
      &:not(.final)::before {
        content: '';
        position: absolute;
        bottom: -24px;
        left: 50%;
        margin-left: -15px;
        border: 12px solid transparent;
        border-top: 12px solid #fff;
        z-index: 2;
      }
      &:not(.final)::after {
        content: '';
        position: absolute;
        bottom: -28px;
        left: 50%;
        margin-left: -17px;
        border: 14px solid transparent;
        border-top: 14px solid v-bind('colors.service.darkBlue');
        z-index: 1;
      }
    }
  }
}
/* footer */
.footer {
  height: 120px;
  padding-top: 40px;
  padding-bottom: 50px;
}
.terms {
  text-align: center;
  margin-bottom: 10px;
}
</style>
